/**
* @Author: Ajay
* @Date:   03-Mar-2017 15:12 +05:30
* @Project: Ezyprocure
* @Filename: global.constants.js
 * @Last modified by:   Abhay
 * @Last modified time: 2020-09-11 09:38 IST
*/



"use strict";
angular.module('ezyprocureApp')
.constant('GLOBAL_CONSTANTS', {
  "CONFIGURED_ADMIN_AMEND_PASS": "11ff2e617368b824c25e02c17a9230feaa9cbfb896fe4ac91ac449326059abd4",
  "CONFIGURED_ADMIN_BNPL_PASS": "6ce8561d325c76dffbfe70b4614f84bdeb2c22fd07030700deaf40825099409a",
  "DEPARTMENTS": ["INTERNAL", "FINANCE", "PAYMENTS", "OPS","COMPLIANCE","OTHERS"],
  "HAOMART_BUYER_ID":[4,238,1854],
  "OPS_STATUS": [ "OPEN", "COMPLETE", "REJECT" ],
  "CROSS_BORDER_FLYER_URL": "content/images/xborder-flyer.png",
  "PROMO_POPUP_IMAGE_URL": "https://sgebiz-artifacts.s3.ap-southeast-1.amazonaws.com/release-notes/test/CB2.png",
  "PROMO_POPUP_FORM_URL": "https://form.jotform.com/211508839498065",
  "CROSS_BORDER_TERMS_AND_CONDITIONS_URL": "https://sgebiz-artifacts.s3-ap-southeast-1.amazonaws.com/release-notes/CB+Terms+And+Conditions.pdf",
  "USER_AGREEMENT_URL": "https://www.sgebiz.com/resources",
  "PRIVACY_POLICY_URL": "https://ezy-dda-doc-store.s3.ap-southeast-1.amazonaws.com/sgebiz-legal/Privacy_Policy+.pdf",
  "CYBERSOURCE": {
    "SECRET_KEY": "5b735c12989b4976816e0bd295b279d701701f8a3ac2411abfeabf00de3e55fe8e98470b8b324feeaf77938ebf18ccc667dc1505bccf488e8a6f2918449f00311156a1b322864890b48c8d1107e0e635d1d020ddea45495fa8335036d2ed0f6d7d6b68eb82a44e29b0a0187579ba40bd05cae05094824fb5bd6ed6c573841c1b",
    "PROFILE_ID": "C1CF2DE7-22B1-4E8E-9AD4-9D1E908BF6A8",
    "ACCESS_KEY": "a02809d69f993800a8628b72d24ddfff"
  },
  "RELEASE_VERSION": "1.0.0",
  "IMAGE_UPLOAD": {
    "FILE_SIZE_LIMIT": 2000000,
    "VALID_IMAGE_EXTENSIONS": ["png,jpeg,jpg"],
    "RESIZE_PARAMS": {"WIDTH": 800, "QUALITY": 0.92}
  },
  "CSV_UPLOAD": {
    "FILE_SIZE_LIMIT": 2000000,
    "VALID_CSV_EXTENSIONS": ["text/comma-separated-values", "text/csv", "application/csv", "application/vnd.ms-excel"]
  },
  "formPatterns": {
    "EMAIL": /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/,
    "PINCODE": /^[0-9]{0,6}$/,
    "MOBILE": /^([()\- x+]*\d[()\- x+]*){8,16}$/,
    "USERNAME": /^[_.A-Za-z0-9-]*$/,
    "NUMBER": /^[0-9]*$/,
    "CARDNUMBER": /^[0-9]{16}$/,
    "COUNTRY_CODE": /^[A-Z]{2}$/,
    "ISO_CODE":/^[0-9]{4}$/,
    "BANK_ACCOUNT_NUMBER_NON_SG":/^[A-Za-z0-9]*$/
  },
  "NG_IDLE": {
    "IDLE_SECONDS": 5,
    "IDLE_TIMEOUT_SECONDS": 5,
    "KEEP_ALIVE_INTERVAL_SECONDS" : 10
  },
  "DASHBOARD_REFRESH_INTERVAL" : 20000,
  "DASHBOARD_REFRESH_TIMEOUT" : 60000,
  "DASHBOARD_INTERVAL_INCREMENT" : 10000,
  "COOKIE_OPTIONS" : {secure: true, samesite: 'lax'},
  "approvalStatus": {
    "approved": "Approved",
    "pending": "Pending",
    "processing": "Processing",
    "rejected": "Rejected",
    "myPr": "MyPr"
  },
  "RESPONSE_STATUSES": ["ALL", "PROCESSED", "PENDING"],
  "PROCESS_ORDER_STATUSES": ['PENDING', 'SUPPLIER_APPROVED', 'SUPPLIER_DEMAND_MODIFIED', 'SUPPLIER_UNABLE_TO_MEET_DEMANDS', 'BUYER_APPROVED', 'BUYER_REJECTED','REQUIREMENT_PENDING_PO'],
  "PO_STATUSES": ["ALL","PENDING", "PROCESSED", "REJECTED","PENDING_CANCELLATION","PO_CANCELLED"],
  "PR_STATUSES": ['ALL','PENDING', 'SUPPLIER_APPROVED', 'SUPPLIER_DEMAND_MODIFIED', 'SUPPLIER_UNABLE_TO_MEET_DEMANDS', 'BUYER_APPROVED', 'BUYER_REJECTED','REQUIREMENT_PENDING_PO','BUYER_CANCELLED_PR','SUPPLIER_REJECTED_PR'],
  "INVOICE_STATUSES": ["PENDING", "PROCESSED", "UNINVOICED", "INVOICED", "ALL"],
  "NOTIFICATION_SETTINGS_TYPES":["NEW_PURCHASE_APPROVAL_REQUEST","REJECTED_APPROVAL_REQUEST","NEW_ITEMS_FOR_APPROVAL","SUPPLIER_ACCEPT_PURCHASE_REQUEST","SUPPLIER_REJECT_PURCHASE_REQUEST","SUPPLIER_UNABLE_TO_MEET_DEMAND","PENDING_GOOD_RECEIVING","NEW_PRICE_UPDATE","E_INVOICING_NOTIFICATION","OTHERS"],
  "LIST_VIEWS": ["Individual", "Consolidated"],
  "SYSTEM_TYPES":["EZYDDA","INTERNAL","CB_ACC"],
  "DAYS": ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"],
  "ITEM_TYPES": ["BUYER"],
  "CUSTOM_DUTY_TYPE": ["Importer", "Freight Forwarder", "Declaring Agent"],
  "PAYMENT_MODELS": ["SUPPLIER_FUNDED", "BUYER_FUNDED", "BUYER_FUNDED_WITH_REBATES", "BUYER_SUPPLIER_CO_FUNDED", "SIP_SUPPLIER_FUNDED", "SIP_BUYER_FUNDED"],
  "RECEIVING_STATUSES": ["ALL", "PENDING", "PROCESSED"],
  "OCR_RESPONSE_REASON_STATUS": ["INVOICE_WITH_CREDIT_NOTE","EZYPROCURE_INVOICE","OTHER"],
  "DEBIT_NOTE_STATUSES": ["ALL", "CREDIT_NOTE_PENDING", "CREDIT_NOTE_GENERATED", "CREDIT_NOTE_REJECTED"],
  "CREDIT_NOTE_TYPES": ["CREDIT_NOTE", "AD_HOC_CREDIT_NOTE"],
  "PAYMENT_METHODS" : [
    {"label":"Ezypay","value":"EZYPAY"},
    {"label":"Cod","value":"COD"},
    {"label":"Nets","value":"NETS"},
    {"label":"Paypal","value": "PAYPAL"},
    {"label":"Cheque","value": "CHEQUE"},
    {"label":"NFRNDS","value": "NFRNDS"},
    {"label":"BANK_TRANSFER","value":"BANK_TRANSFER"},
    {"label":"Others","value":"OTHERS"}
  ],
  "TRANSACTION_TYPES_BUYER": [
    {
        "label":"Manage Cards",
        "value":"MANAGE_CARDS"
    },{
      "label":"Payment",
      "value":"PAYMENT"
    },{
      "label":"Export To XERO",
      "value":"EXPORT_TO_XERO"
    },{
      "label":"Purchase Request",
      "value":"PURCHASE_REQUEST"
    },
    {
      "label":"Purchase Order",
      "value":"PURCHASE_ORDER"
    },
    {
      "label":"Goods Receipt Note" ,
      "value":"GOOD_RECIEPT_NOTE"
    },{
      "label":"E-Invoice",
      "value":"E_INVOICE",
    },{
      "label":"Credit Note",
      "value":"CREDIT_NOTE"
    },
    // {
    //   "label":"Items Approval",
    //   "value":"ITEMS_APPROVAL"
    // },
    {
      "label": "Purchase Request Item Approval",
      "value": "PURCHASE_REQUEST_FOR_APPROVAL"
    },
    // {
    //   "label": "Process Order",
    //   "value": "PROCESS_ORDER"
    // },
    // {
    //   "label": "E-Authorization Request",
    //   "value": "E_AUTHORIZATION_REQUEST"
    // },
    // {
    //   "label":"GRN",
    //   "value":"GRN"
    // },
    {
      "label":"Order Template",
      "value":"ORDER_TEMPLATE"
    },{
      "label":"OCR GRN Edit",
      "value":"OCR_GRN_EDIT"
    },{
      "label":"OCR Acceptance",
      "value":"OCR_ACCEPTANCE"
    },{
      "label":"Adhoc Supplier Mapping",
      "value":"ADHOC_SUPPLIER_MAPPING"
    },{
      "label": "Sales Order",
      "value": "SALES_ORDER"
    },{
      "label": "Export Transaction Details",
      "value": "EXPORT_TRANSACTION_DETAILS"
    },{
      "label": "SIP invoice Approval Request",
      "value": "SIP_INVOICE_APPROVAL_REQUEST"
    },{
      "label": "SIP invoice upload",
      "value": "SIP_INVOICE"
    },
    // {
    //   "label": "AP Purchase Export",
    //   "value": "AP_EXPORT"
    // },
    {
      "label": "Item Business Mapping ",
      "value": "ITEM_BUSINESS_MAPPING"
    },
    // {
    //   "label": "External Payment",
    //   "value": "EXTERNAL_PAYMENT_FILE_UPLOAD"
    // }
    {
      "label": "Report Generation",
      "value": "REPORT_GENERATION"
    },{
      "label": "Export to ERP",
      "value": "EXPORTED_TO_ERP"
    },
  ],

  "TRANSACTION_TYPES_SUPPLIER": [
    {
        "label":"Manage Cards",
        "value":"MANAGE_CARDS"
    },{
      "label":"Payment",
      "value":"PAYMENT"
    },{
      "label":"Export To XERO",
      "value":"EXPORT_TO_XERO"
    },
    {
      "label":"Purchase Order",
      "value":"PURCHASE_ORDER"
    },
    {
      "label":"E-Invoice",
      "value":"E_INVOICE",
    },{
      "label":"Credit Note",
      "value":"CREDIT_NOTE"
    },
    // {
    //   "label": "Process Order",
    //   "value": "PROCESS_ORDER"
    // },
    {
      "label": "Sales Order",
      "value": "SALES_ORDER"
    },{
      "label": "Export Transaction Details",
      "value": "EXPORT_TRANSACTION_DETAILS"
    },{
      "label": "SIP invoice Approval Request",
      "value": "SIP_INVOICE_APPROVAL_REQUEST"
    },{
      "label": "SIP invoice upload",
      "value": "SIP_INVOICE"
    },
    // {
    //   "label": "AP Purchase Export",
    //   "value": "AP_EXPORT"
    // },
    {
      "label": "Item Business Mapping ",
      "value": "ITEM_BUSINESS_MAPPING"
    },{
      "label": "Report Generation",
      "value": "REPORT_GENERATION"
    },{
      "label": "Export to ERP",
      "value": "EXPORTED_TO_ERP"
    },
  ],
  "ITEMS_TYPES": ["SUPPLIER"],
  "CURRENCIES": ["AED","USD","TWD","THB","SGD","INR","MYR","IDR", "AUD", "PHP", "CHF","JPY","KRW","VND","CNY","RMB","NZD","EUR","GBP","QAR"],
  "SUPPLIER_VISIBILITY_TYPES": ["ALLOCATED", "ADHOC"],
  "SUPPLIER_VISIBILITY_TYPES_BUYER": ["ALL", "ALLOCATED", "ADHOC"],
  "PEPPOL_TRANSMISSION_STATUSES": ["PENDING", "PROCESSED", "SENT","FAILED"],
  "emailConfiguration": {
    "SUBJECT_SIZE_LIMIT": 100,
    "BODY_SIZE_LIMIT": 500
  },
  "DEFAULT_GST": 7,
  "DEFAULT_DATE_FORMAT": "dd/MM/yyyy",
  "DEFAULT_DATE_TIME_FORMAT":"dd/MM/yyyy HH:mm:ss",
  "DEFAULT_BUSINESS_REPORT_DATE_FORMAT":"dd-MMMM-yyyy",
  "DEFAULT_CREDIT_TERM": 7,
  "DEFAULT_LANGUAGE": "en",
  "DEFAULT_CURRENCY": "SGD",
  "ADHOC_PO":[{ "name": "Purchase Request", "value":"REQUEST"}],
  "ACCOUNT_TYPE":["ALL","LIVE_ACCOUNTS", "DEMO_ACCOUNTS"],
  "ISSUERS":["ALL","VISA","MASTERCARD"],
  "STOCK_LIST_VIEWS": [{"label": "Breakdown By Items", "value":"CONSOLIDATED_VIEW"},{"label": "Breakdown By Outlets", "value":"BREAKDOWN_BY_OUTLET"}],
  "COMBINE_BY_ITEMS_AND_OUTLETS_VIEWS": [{"label": "Breakdown By Items", "value":"CONSOLIDATED_VIEW"},{"label": "Breakdown By Outlets", "value":"BREAKDOWN_BY_OUTLET"},{"label": "Combine by Items and Outlets", "value":"COMBINE_BY_ITEMS_AND_OUTLETS"}],
  "STOCK_PR_STATUS": [{"label": "ALL", "value":"ALL"},{"label": "PENDING", "value":"PENDING"},{"label": "PROCESSED", "value":"PROCESSED"}],
  "COSTING_REPORT_VIEW_TYPES":[{viewby: "ITEM_NAME"},{viewby: "SUB_ITEM_GROUP"},{viewby: "MAIN_ITEM_GROUP"}],
  "PURCHASE_TREND_VIEW_TYPES" : ['Quarter','Month'],
  "MONTHS": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  "QUARTERS": ["Q1", "Q2", "Q3", "Q4"],
  "SHOW_UOB_BANNER": false,
  "SHOW_EZYSOURCE_BANNER": true,
  "CREDIT_CARD": {
    "MONTHS": ["01","02","03","04","05","06","07","08","09","10","11","12"],
    "YEAR": {"2018": 18, "2019": 19, "2020": 20, "2021":21, "2022":22, "2023":23, "2024":24, "2025":25, "2026":26, "2027":27,"2028":28,"2029":29,"2030":30,"2031":31,"2032":32,"2033":33,"2034":34,"2035":35,"2036":36,"2037":37,"2038":38},
    "TYPES": {"cycle1" : "cycle 15 card", "cycle2" : "cycle 30/31 card", "cycle3": "Full Cycle"},
    "SECURITY_CODE": "000"
  },
    "issuedCountry":"Issued Country",
    "issuedBank":"Issued Bank",
  "PRICE_TRENDS_VIEWS": [{"label": "View By Day", "value":"VIEW_BY_DAY"}, {"label": "View By Month", "value":"VIEW_BY_MONTH"}, {"label": "View By Quarter", "value":"VIEW_BY_QUARTER"}],
  "UOB_BIZPLUS_URL": "https://uob.com.sg/bizplus",
  "SSL_CERTIFICATE_URL": "https://www.websecurity.symantec.com/ssl-certificate",
  "EZYPAYMENT_INVOICE_TYPES": ["INTERNAL", "EXTERNAL"],
  "SIP_EZYPAYMENT_INVOICE_TYPES": ["INTERNAL", "EXTERNAL","EXTERNALSIP","EXTERNAL_SIP_WITH_CREDIT_NOTE"],
  "OCTOPUS_EZYPAYMENT_INVOICE_TYPES": [
    { "key": "INTERNAL", "displayValue": "INTERNAL" },
    { "key": "EXTERNAL", "displayValue": "EXTERNAL" },
    { "key": "EXTERNALSIP", "displayValue": "EXTERNAL SIP" },
    { "key": "EXTERNAL_SIP_WITH_CREDIT_NOTE", "displayValue": "EXTERNAL WITH CREDIT NOTE" }
  ],
  "MERCHANT_PASSWORD": "19900801",
  "eGIRO_ADMIN_ACCESS_PASSWORD":"20210831",
  "VISA_PAYMENT_PASSWORD":"19900810",
  "GATEWAY_TYPES": ["MPGS", "WORLD_PAY"],
  "EXCEL_UPLOAD": {
    "FILE_SIZE_LIMIT": 2000000,
    "VALID_EXCEL_EXTENSIONS": ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
  },
  "SATS_VALID_FILE_EXTENSIONS":['image/png', 'image/jpeg', 'image/png', 'application/csv', 'text/comma-separated-values', 'application/pdf', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'application/zip','application/x-zip','application/x-zip-compressed', 'application/gzip'],
  "SATS_STATUS": ['RELEASED', 'PROCESSED', 'POSTED', 'FAILED', 'REJECTED'],
  "FILE_TYPES": ['XML', 'CSV', 'TXT'],
  "PURCHASE_METHOD_TYPES": [{"label": "Purchase By Items", "value": "PURCHASE_BY_ITEMS"}, {"label": "Purchase By Template", "value": "PURCHASE_BY_TEMPLATE"}],
  "PAYMENT_STATUSES": [
    { "label": "status", "value": "PAID" },
    { "label": "status", "value": "UNPAID" },
    { "label": "status", "value": "PENDING_APPROVAL" },
    { "label": "status", "value": "FAILED" },
    { "label": "status", "value": "CANCELLED" },
    { "label": "status", "value": "VOID" }
  ],
  "WORLDPAY_PAYMENT_STATUSES": [
    { "label": "status", "value": "PAID" },
    { "label": "status", "value": "IN_PROGRESS" },
    { "label": "status", "value": "APPROVAL_REJECTED" },
    { "label": "status", "value": "FAILED" },
    { "label": "status", "value": "APPROVAL_PENDING" }
  ],
"OCBC_PAYMENT_STATUSES":[{"label":"status","value":"PAID"},
  {"label":"status","value":"FAILED"},
  {"label":"status","value":"PENDING"},
  {"label":"status","value":"IN_PROGRESS"},
  {"label":"status","value":"COLLECTION_FAILED"},
  {"label":"status","value":"PROCESSING"},
  {"label":"status","value":"GIRO_UPLOADED"},
  {"label":"status","value":"REMITTANCE_FAILED"}
],
"APPROVAL_STATUSES": [
  {"label":"Pending","value":"PENDING"},
  {"label":"Approved","value":"APPROVED"}
],
"SIP_APPROVAL_STATUSES": [
  {"label":"All","value":''},
  {"label":"Pending","value":"PENDING"},
  {"label":"Approved","value":"APPROVED"}
],
"SIP_EXTERNAL_APPROVAL_STATUSES": [
  {"label":"All","value":''},
  {"label":"New","value":'NEW'},
  {"label":"Pending","value":"PENDING"},
  {"label":"Approved","value":"APPROVED"}
],
  "TRANSACTION_STATUSES":[
  {"label":"status","value":"NA"},
  {"label":"status","value":"GIRO_UPLOADED"},
  {"label":"status","value":"PROCESSED"},
  {"label":"status","value":"FILE_REJECTED"},
  {"label":"status","value":"FILE_ACCEPTED"},
  {"label":"status","value":"DUPLICATE_FILE"},
  {"label":"status","value":"TRANSACTION_REJECTED"},
  {"label":"status","value":"IN_PROCESS"},
  {"label":"status","value":"PAYMENT_FILE_REJECTED"},
  {"label":"status","value":"TRANSACTION_STOPPED"},
  {"label":"status","value":"PAYMENT_FILE_FAILED"},
  {"label":"status","value":"PROCESSING"},
 ],
 "GIRO_TYPE" : [
   {"label": "all", "value":"ALL"},
   {"label": "fast", "value":"FAST"},
   {"label":"normal", "value": "NORMAL"}
 ],
 "PAY_TYPE" : [
  {"label": "uen", "value":"UEN"},
  {"label": "msisdn", "value":"MSISDN"},
  {"label":"nric", "value": "NRIC"},
  {"label": "bankbic", "value":"BANKBIC"}
],
 "SOCK_TRANSFER_STATUSES":[
   {"label":"New", "value":"NEW"},
   {"label":"Processed", "value":"PROCESSED"},
   {"label":"Exported", "value":"EXPORTED"}
 ],
 "paymentApprovalGroups" : [
      {
          value: "GROUP_1",
          label: "GROUP A"
      },
      {
          value: "GROUP_2",
          label: "GROUP B"
      },
      {
          value: "GROUP_3",
          label: "GROUP C"
      },
      {
          value: "GROUP_4",
          label: "GROUP D"
      },
      {
          value: "GROUP_5",
          label: "GROUP E"
      }
    ],
    "accessControlData" : [
      {
        'label' : 'global.userAccess.types.procurement',
        'value' :  [
          {
            'ariaLabel': 'accessForNegativeBuyingBuyerView',
            'accessControl': 'accessForNegativeBuyingBuyerView',
            'translation' : 'global.userAccessTypes.negativeBuying',
            'title': 'global.userAccess.title.negativeBuying',
            'serchText':'Negative Buying',
            'authorization': 'BOTH'
          },
          {
            'ariaLabel': 'accessForGrnPriceUpdate',
            'accessControl': 'accessForGrnPriceUpdate',
            'translation' : 'global.userAccessTypes.GrnPriceUpdate',
            'title': 'global.userAccess.title.GrnPriceUpdate',
            'serchText':'GrnPriceUpdate',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForDeliveryDateChange',
            'accessControl': 'accessForAmendGrnDeliveryDate',
            'translation' : 'global.userAccessTypes.editableDeliveyDate',
            'title': 'global.userAccess.title.grnDeliveryDateEdit',
            'serchText':'Delivery date change',
            'authorization': 'ADMIN_BUYER'
          },

          {
            'ariaLabel': 'accessForGrnReceiveDateEdit',
            'accessControl': 'accessForGRNReceiveDateEdit',
            'translation' : 'global.userAccessTypes.grnReceiveDateEdit',
            'title': 'global.userAccess.title.grnReceiveDateEdit',
            'serchText':'GRN Receive Date Edit',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForPromotion',
            'accessControl': 'accessForPromotion',
            'translation' : 'global.userAccessTypes.promotion',
            'title': 'global.userAccess.title.promotions',
            'serchText':'Promotion',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'accessForConsignment',
            'accessControl': 'accessForConsignment',
            'translation' : 'global.userAccessTypes.consignment',
            'title': 'global.userAccess.title.consignment',
            'serchText':'Consignment',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'accessForNoDeviationApproval',
            'accessControl': 'accessForNoDeviationApproval',
            'translation' : 'global.userAccessTypes.noDeviationApproval',
            'title': 'global.userAccess.title.noDeviationApproval',
            'serchText':'No Deviation Approval',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'priceUpdate',
            'accessControl': 'accessForPriceUpdate',
            'translation' : 'global.userAccessTypes.priceUpdate',
            'title': 'global.userAccess.title.priceUpdate',
            'serchText':'Price Update',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForItemApproval',
            'accessControl': 'accessForItemApproval',
            'translation' : 'global.userAccessTypes.itemApproval',
            'title': 'global.userAccess.title.itemApproval',
            'serchText':'Item Approval',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForBudgetApproval',
            'accessControl': 'accessForBudgetApproval',
            'translation' : 'global.userAccessTypes.budgetApproval',
            'title': 'global.userAccess.title.budgetApproval',
            'serchText':'Budget Approval',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForBudgetLimited',
            'accessControl': 'accessForBudgetLimited',
            'translation' : 'global.userAccessTypes.budgetLimited',
            'title': 'global.userAccess.title.budgetLimited',
            'serchText':'Budget Limited',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForAdhocPo',
            'accessControl': 'accessForAdhocPo',
            'translation' : 'global.userAccessTypes.adhocPo',
            'title': 'global.userAccess.title.adhocPO',
            'serchText':'Adhoc Po',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForAutoOrderingApproval',
            'accessControl': 'accessForAutoOrderingApproval',
            'translation' : 'global.userAccessTypes.autoOrderingApproval',
            'title': 'global.userAccess.title.autoOrderingApproval',
            'serchText':'Auto Ordering Approval',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForBuyerOnly',
            'accessControl': 'accessForBuyerOnly',
            'translation' : 'global.userAccessTypes.buyerOnly',
            'title': '',
            'serchText':'Buyer Only',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForManageSupplier',
            'accessControl': 'accessForManageSupplier',
            'translation' : 'global.userAccessTypes.manageSupplier',
            'title': 'global.userAccess.title.manageSupplier',
            'serchText':'Manage Supplier',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForPriceDisplay',
            'accessControl': 'accessForPriceDisplay',
            'translation' : 'global.userAccessTypes.priceDisplay',
            'title': 'global.userAccess.title.priceDisplay',
            'serchText':'Price Display',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'accessForApprover',
            'accessControl': 'accessForApprover',
            'translation' : 'global.userAccessTypes.approver',
            'title': 'global.userAccess.title.approvar',
            'serchText':'Approver',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForGRMandatory',
            'accessControl': 'accessForGRMandatory',
            'translation' : 'global.userAccessTypes.gRMandatory',
            'title': 'global.userAccess.title.grMandatory',
            'serchText':'GR Mandatory',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForCentralPurchase',
            'accessControl': 'accessForCentralPurchase',
            'translation' : 'global.userAccessTypes.centralPurchase',
            'title': '',
            'serchText':'Central Purchase',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForManageApprovalLevel',
            'accessControl': 'accessForManageApprovalLevel',
            'translation' : 'global.userAccessTypes.manageApprovalLevel',
            'title': 'global.userAccess.title.manageApprovalLevel',
            'serchText':'Manage Approval Level',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForReceivingOrder',
            'accessControl': 'accessForReceivingOrder',
            'translation' : 'global.userAccessTypes.receivingOrders',
            'title': 'global.userAccess.title.receiveOrders',
            'serchText':'Receive Orders',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForItemBuyerMapping',
            'accessControl': 'accessForItemBuyerMapping',
            'translation' : 'global.userAccessTypes.itemBuyerMapping',
            'title': 'global.userAccess.title.itemBuyerMapping',
            'serchText':'Item Buyer Mapping',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForUploadSalesOrder',
            'accessControl': 'accessForUploadSalesOrder',
            'translation' : 'global.userAccessTypes.uploadSalesOrder',
            'title': 'global.userAccess.title.uploadsSalesOrder',
            'serchText':'Upload Sales Order',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForSkipPRApproval',
            'accessControl': 'accessForSkipPRApproval',
            'translation' : 'global.userAccessTypes.skipPRApproval',
            'title': 'global.userAccess.title.skipPrApproval',
            'serchText':'Skip PR Approval',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForAvailableQuantity',
            'accessControl': 'accessForAvailableQuantity',
            'translation' : 'global.userAccessTypes.showAvailableQuantity',
            'title': 'global.userAccess.title.showAvailableQuantity',
            'serchText':'Show Available Quantity',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForContractedSuppliers',
            'accessControl': 'accessForContractedSuppliers',
            'translation' : 'global.navbar.contractedSuppliers',
            'title': 'global.userAccess.title.contractedSuppliers',
            'serchText':'Contracted Suppliers',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForAdhocDiscount',
            'accessControl': 'accessForAdhocDiscount',
            'translation' : 'global.userAccessTypes.adhocDiscount',
            'title': 'global.userAccess.title.adhocDiscount',
            'serchText':'Adhoc Discount',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForManageOrderTemplate',
            'accessControl': 'accessForManageOrderTemplate',
            'translation' : 'global.userAccessTypes.manageOrderTemplate',
            'title': 'global.userAccess.title.manageOrderTemplate',
            'serchText':'Manage Order Template',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForSupplierUnableToMeetDemands',
            'accessControl': 'accessForSupplierUnableToMeetDemands',
            'translation' : 'ezyprocureApp.buyerDashboard.supplierUnableToMeetDemands',
            'title': 'global.userAccess.title.supplierUnableToMeetDemands',
            'serchText':'Supplier Unable To Meet Demands',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForInstantPO',
            'accessControl': 'accessForInstantPO',
            'translation' : 'global.userAccessTypes.instantPO',
            'title': 'global.userAccess.title.instantPO',
            'serchText':'Instant PO',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForCancelPO',
            'accessControl': 'accessForCancelPO',
            'translation' : 'global.userAccessTypes.cancelPO',
            'title': 'global.userAccess.title.cancelPO',
            'serchText':'Cancel PO',
            'authorization': 'BOTH'
          },
          {
            'ariaLabel': 'access for cancel PR',
            'accessControl': 'accessForCancelPr',
            'translation' : 'global.userAccessTypes.cancelPR',
            'title': 'global.userAccessTypes.cancelPR',
            'serchText':'',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForGoodsReturnNotice',
            'accessControl': 'accessForGoodsReturnNotice',
            'translation' : 'global.userAccessTypes.goodsReturnNotice',
            'title': 'global.userAccess.title.goodsReturnNotice',
            'serchText':'Goods Return Notice',
            'authorization': 'BOTH'
          },
          {
            'ariaLabel': 'accessForBarcodeSearch',
            'accessControl': 'accessForBarcodeSearch',
            'translation' : 'global.barcodeSearch',
            'title': 'global.barcodeSearch',
            'serchText':'Barcode Search',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForGRAddBuffer',
            'accessControl': 'accessForGRAddBuffer',
            'translation' : 'global.graddbuffer',
            'title': 'global.graddbuffertitle',
            'serchText':'GR Additional Qty Buffer',
            'authorization': 'ADMIN_BUYER'
        },
        {
            'ariaLabel': 'accessForMandatoryGRN',
            'accessControl': 'accessForMandatoryGRN',
            'translation' : 'global.userAccessTypes.MandatoryGRN',
            'title': 'global.userAccess.title.MandatoryGRN',
            'serchText':'Mandatory GRN',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForManageBlanketOrder',
            'accessControl': 'accessForManageBlanketOrder',
            'translation' : 'global.userAccessTypes.accessForManageBlanketOrder',
            'title': 'global.userAccess.title.accessForManageBlanketOrder',
            'serchText':'Manage Blanket Order',
            'authorization': 'BOTH'
          },
          {
            'ariaLabel': 'accessForBlanketOrderBuying',
            'accessControl': 'accessForBlanketOrderBuying',
            'translation' : 'global.userAccessTypes.accessForBlanketOrderBuying',
            'title': 'global.userAccess.title.accessForBlanketOrderbuying',
            'serchText':'Blanket Order Buying',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForBuyerItemCodeFeatures',
            'accessControl': 'accessForBuyerItemCodeFeatures',
            'translation' : 'global.userAccessTypes.accessForBuyerItemCodeFeatures',
            'title': 'global.userAccess.title.accessForBuyerItemCodeFeatures',
            'serchText':'Buyer Item Code Features',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForMandatoryGrnData',
            'accessControl': 'accessForMandatoryGrnData',
            'translation' : 'global.userAccessTypes.MandatoryGRNData',
            'title': 'global.userAccess.title.MandatoryGRNData',
            'serchText':'Mandatory GRN Data',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForTrackSupplierNotification',
            'accessControl': 'accessForTrackSupplierNotification',
            'translation' : 'global.userAccessTypes.trackSupplierNotification',
            'title': 'global.userAccess.title.trackSupplierNotification',
            'serchText':'Track Supplier Notification',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForAdhocPoForm',
            'accessControl': 'accessForAdhocPoForm',
            'translation' : 'global.userAccessTypes.adhocPoForm',
            'title': 'global.userAccess.title.adhocPOForm',
            'serchText':'Adhoc Po Form',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForGrnAddItem',
            'accessControl': 'accessForGrnAddItem',
            'translation' : 'global.userAccessTypes.addGRNItem',
            'title': 'global.userAccess.title.addGRNItem',
            'serchText':'Add GRN Item',
            'authorization': 'ADMIN_BUYER'
          }
        ]
      },{
        'label' : 'global.userAccess.types.payment',
        'value' :  [
          {
            'ariaLabel': 'accessForPayViaUOBVPS',
            'accessControl': 'accessForPayViaUOBVPS',
            'translation' : 'global.userAccessTypes.ezyPayment',
            'title': 'global.userAccess.title.ezyPayment',
            'serchText':'EzyPayment',
            'authorization': 'BOTH'
          }
          ,{
            'ariaLabel': 'accessForEzypaymentCrossborder',
            'accessControl': 'accessForEzypaymentCrossborder',
            'translation' : 'global.userAccessTypes.cbEzyPayment',
            'title': 'global.userAccess.title.cbEzypayment',
            'serchText':'Crossborder EzyPayment',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'accessForPaymentApproval',
            'accessControl': 'accessForPaymentApproval',
            'translation' : 'global.userAccessTypes.paymentApproval',
            'title': 'global.userAccess.title.paymentApproval',
            'serchText':'Payment Approval',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'paymentOTPEnabled',
            'accessControl': 'paymentOTPEnabled',
            'translation' : 'global.userAccessTypes.paymentOTPEnabled',
            'title': 'global.userAccess.title.paymentOTPEnabled',
            'serchText':'Enable Payment OTP',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'uobAllCards',
            'accessControl': 'accessForUobAllCardsPayment',
            'translation' : 'global.userAccessTypes.uobPaymentAllCards',
            'title': 'global.userAccessTypes.uobPaymentAllCards',
            'serchText':'UOB/All Card Payments',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'ocbcPayments',
            'accessControl': 'accessForOCBCPayment',
            'translation' : 'global.userAccessTypes.odbcPayments',
            'title': 'global.userAccessTypes.odbcPayments',
            'serchText':'OCBC Payments',
            'authorization': 'BOTH'
          },
          {
            'ariaLabel': 'EDDA-Collection',
            'accessControl': 'accessForEddaCollection',
            'translation' : 'global.userAccessTypes.EDDA-Collection',
            'title': 'global.userAccessTypes.EDDA-Collection',
            'serchText':'EDDA-Collection',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForConsolidateGiro',
            'accessControl': 'accessForConsolidateGiro',
            'translation' : 'global.userAccessTypes.consolidatedGiro',
            'title': 'global.userAccessTypes.consolidatedGiro',
            'serchText':'Consolidated GIRO',
            'authorization': 'ADMIN_SUPPLIER'
          },
          {
            'ariaLabel': 'accessForA2APayment',
            'accessControl': 'accessForA2APayment',
            'translation' : 'global.userAccessTypes.accessForA2APayment',
            'title': 'global.userAccessTypes.accessForA2APayment',
            'serchText':'A2A Payment',
            'authorization': 'ADMIN_SUPPLIER'
          },
          
          {
            'ariaLabel': 'accessForBnplPayment',
            'accessControl': 'accessForBnplPayment',
            'translation' : 'global.userAccessTypes.bnpl',
            'title': 'global.userAccess.title.bnpl',
            'serchText':'BNPL',
            'authorization': 'BOTH'
          }
        ]
      },{
        'label' : 'INTEGRATION',
        'value' :  [
          {
            'ariaLabel': 'accessForAXIntegration',
            'accessControl': 'accessForAXIntegration',
            'translation' : 'global.userAccessTypes.axIntegrateAccess',
            'title': 'global.userAccess.title.axIntegrateAccess',
            'serchText':'Ax Integrate Access',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'accessForXeroExport',
            'accessControl': 'accessForXeroExport',
            'translation' : 'global.userAccessTypes.accessXERO',
            'title': 'global.userAccess.title.xeroIntegration',
            'serchText':'XERO Integration',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'accessForGeoExport',
            'accessControl': 'accessForGeoExport',
            'translation' : 'global.userAccessTypes.geoExport',
            'title': 'global.userAccess.title.geoExport',
            'serchText':'Geo Export',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForQuickBooksExport',
            'accessControl': 'accessForQuickBooksExport',
            'translation' : 'global.userAccessTypes.quickBooksExport',
            'title': 'global.userAccess.title.quickBooksExport',
            'serchText':'QuickBooks Export',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'accessForExportAccPac',
            'accessControl': 'accessForExportAccPac',
            'translation' : 'global.userAccessTypes.exportAccPac',
            'title': 'global.userAccess.title.exportToAccpac',
            'serchText':'Export To AccPac',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForExportToMyob',
            'accessControl': 'accessForExportToMyob',
            'translation' : 'global.userAccessTypes.exportToMyob',
            'title': 'global.userAccess.title.exportToMyob',
            'serchText':'Export to MYOB',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForJurnalExport',
            'accessControl': 'accessForJurnalExport',
            'translation' : 'global.userAccessTypes.exportToJurnal',
            'title': 'global.userAccess.title.exportToJurnal',
            'serchText':'Export to Jurnal',
            'authorization': 'BOTH'
          },
          {
            'ariaLabel': 'accessForSageExport',
            'accessControl': 'accessForSageExport',
            'translation' : 'global.userAccessTypes.exportSAGA50',
            'title': 'global.exportSAGA50',
            'serchText':'SMS',
            'authorization': 'BOTH'
          },
          {
            'ariaLabel': 'accessForSunSystemExport',
            'accessControl': 'accessForSunSystemExport',
            'translation' : 'global.userAccessTypes.exportToSunSystem',
            'title': 'global.userAccess.title.exportToSunSystem',
            'serchText':'Export to Sun System',
            'authorization': 'BOTH'
          },
          {
            'ariaLabel': 'accessForNetSuiteExport',
            'accessControl': 'accessForNetSuiteExport',
            'translation' : 'global.userAccessTypes.exportToNetSuite',
            'title': 'global.userAccess.title.exportToNetSuite',
            'serchText':'Export to NetSuite',
            'authorization': 'BOTH'
          },
          {
            'ariaLabel': 'accessForApExport',
            'accessControl': 'accessForApExport',
            'translation' : 'global.userAccessTypes.apExport',
            'title': '',
            'serchText':'AP Export',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForMillionExport',
            'accessControl': 'accessForMillionExport',
            'translation' : 'global.userAccessTypes.millionExport',
            'title': 'global.userAccess.title.millionExport',
            'serchText':'Million Export',
            'authorization': 'BOTH'
          },
          {
            'ariaLabel': 'accessForOciExport',
            'accessControl': 'accessForOciExport',
            'translation' : 'global.buttons.ociExport',
            'title': 'global.userAccess.title.ociExport',
            'serchText':'OCI export',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForSQLExport',
            'accessControl': 'accessForSQLExport',
            'translation' : 'global.userAccessTypes.accessForSqlExport',
            'title': '',
            'serchText':'SQL Export',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForOdooApExport',
            'accessControl': 'accessForOdooApExport',
            'translation' : 'global.userAccessTypes.accessForOdooApExport',
            'title': '',
            'serchText':'Odoo AP Export',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForAutocountExport',
            'accessControl': 'accessForAutocountExport',
            'translation' : 'global.userAccessTypes.accessForAutocountExport',
            'title': '',
            'serchText':'Autocount Export',
            'authorization': 'ADMIN_BUYER'
          },
        ]
      },{
        'label' : 'global.userAccess.types.core',
        'value' :  [
          {
            'ariaLabel': 'accessForReports',
            'accessControl': 'accessForReports',
            'translation' : 'global.userAccessTypes.reports',
            'title': 'global.userAccess.title.reports',
            'serchText':'Reports',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'accessForEmailService',
            'accessControl': 'accessForEmailService',
            'translation' : 'global.form.email',
            'title': 'global.userAccess.title.email',
            'serchText':'E-mail',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'accessForMaster',
            'accessControl': 'accessForMaster',
            'translation' : 'global.userAccessTypes.master',
            'title': 'global.userAccess.title.master',
            'serchText':'Master',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'accessForTools',
            'accessControl': 'accessForTools',
            'translation' : 'global.userAccessTypes.tools',
            'title': 'global.userAccess.title.tools',
            'serchText':'Tools',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'accessForBusinessCharts',
            'accessControl': 'accessForBusinessCharts',
            'translation' : 'global.userAccessTypes.businessCharts',
            'title': 'global.userAccess.title.businessCharts',
            'serchText':'Business Charts',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'accessForAdhocTools',
            'accessControl': 'accessForAdhocTools',
            'translation' : 'global.userAccessTypes.adhocTools',
            'title': 'global.userAccess.title.adhocTools',
            'serchText':'Adhoc Tools',
            'authorization': 'ADMIN_BUYER'
          }
        ]
      },{
        'label' : 'global.userAccess.types.systemAccess',
        'value' :  [
          {
            'ariaLabel': 'accessForPEPPOL',
            'accessControl': 'accessForPEPPOL',
            'translation' : 'global.navbar.ezyap',
            'title': '',
            'serchText':'EZYAP',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'accessForEzyprocure',
            'accessControl': 'accessForEzyprocure',
            'translation' : 'global.title',
            'title': '',
            'serchText':'Ezyprocure',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'accessForSFTP',
            'accessControl': 'accessForSFTP',
            'translation' : 'global.userAccessTypes.SFTPSetup',
            'title': '',
            'serchText':'SFTP Setup',
            'authorization': 'BOTH'
            },{
            'ariaLabel': 'accessForSATS',
            'accessControl': 'accessForSATS',
            'translation' : 'global.userAccessTypes.sats',
            'title': '',
            'serchText':'Ezyprocure SATS',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'accessForEzysource',
            'accessControl': 'accessForEzysource',
            'translation' : 'global.buttons.ezysource',
            'title': '',
            'serchText':'Ezysource',
            'authorization': 'BOTH'
          },
          {
            'ariaLabel': 'accessForEzyinventory',
            'accessControl': 'accessForEzyinventory',
            'translation' : 'global.userAccessTypes.accessForEzyinventory',
            'title': '',
            'serchText':'EzyInventory',
            'authorization': 'BOTH'
          },{
            'ariaLabel': 'Access For Only EzyAP',
            'accessControl': 'accessForOnlyEzyAP',
            'translation' : 'global.navbar.onlyEzyap',
            'title': '',
            'serchText':'EZYAP',
            'authorization': 'BOTH'
          },
          {
            'ariaLabel': 'accessForEzy2Finance',
            'accessControl': 'accessForEzy2Finance',
            'translation' : 'global.userAccessTypes.accessForManageEzy2Finance',
            'title': 'global.userAccess.title.manageEzy2Finance',
            'serchText':'Ezy2Finance',
            'authorization': 'BOTH'
          },
          {
            'ariaLabel': 'accessForOnlyEzy2Finance',
            'accessControl': 'accessForOnlyEzy2Finance',
            'translation' : 'global.userAccessTypes.accessForManageOnlyEzy2Finance',
            'title': 'global.userAccess.title.manageOnlyEzy2Finance',
            'serchText':'OnlyEzy2Finance',
            'authorization': 'BOTH'
          }
        ]
      },{
        'label' : 'global.userAccess.types.finance',
        'value' :  [
          {
            'ariaLabel': 'accessForOcr',
            'accessControl': 'accessForOcr',
            'translation' : 'global.userAccessTypes.ocr',
            'title': 'global.userAccess.title.ocr',
            'serchText':'OCR',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForOcrStaffInterface',
            'accessControl': 'accessForOcrStaffInterface',
            'translation' : 'global.userAccessTypes.ocrStaffInterface',
            'title': 'global.userAccess.title.ocrStaffInterface',
            'serchText':'Ocr Staff Interface',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'access for ocr verification',
            'accessControl': 'accessForOcrVerification',
            'translation' : 'global.userAccessTypes.ocrVerification',
            'title': 'global.userAccess.title.ocrVerification',
            'serchText':'OCR Verification',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForOcrCollectionStaffInterface',
            'accessControl': 'accessForOcrCollectionStaffInterface',
            'translation' : 'global.userAccessTypes.ocrCollectionStaffInterface',
            'title': 'global.userAccess.title.ocrCollectionsStaffInterface',
            'serchText':'Ocr Collection Staff Interface',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForMassAcceptOcr',
            'accessControl': 'accessForMassAcceptOcr',
            'translation' : 'global.userAccessTypes.massAcceptOCR',
            'title': 'global.userAccess.title.massAcceptOcr',
            'serchText':'Mass accept OCR',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForOcrGrnEdit',
            'accessControl': 'accessForOcrGrnEdit',
            'translation' : 'global.userAccessTypes.ocrGrnEdit',
            'title': 'global.userAccess.title.ocrGrnEdit',
            'serchText':'Ocr Grn Edit',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForOcrPoQtyPrice',
            'accessControl': 'accessForOcrPoQtyPrice',
            'translation' : 'global.userAccessTypes.ocrPOQtyPrice',
            'title': '',
            'serchText':'OCR PO Qty/Price',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForGlModule',
            'accessControl': 'accessForGlModule',
            'translation' : 'global.userAccessTypes.glModule',
            'title': 'global.userAccess.title.',
            'serchText':'GL Module',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForGlCodeManagement',
            'accessControl': 'accessForGlCodeManagement',
            'translation' : 'global.userAccessTypes.manageGlCode',
            'title': 'global.userAccess.title.glCodeManagement',
            'serchText':'Manage GL Code',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForGRNEditable',
            'accessControl': 'accessForGRNEditable',
            'translation' : 'global.userAccessTypes.gRNEditable',
            'title': 'global.userAccess.title.grnEditable',
            'serchText':'GRN Editable',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForMandatoryHardCopyInvoiceNumber',
            'accessControl': 'accessForMandatoryHardCopyInvoiceNumber',
            'translation' : 'global.userAccessTypes.mandatoryHardCopyInvoiceNumber',
            'title': 'global.userAccess.title.mandatoryHardCopyInvoiceNumber',
            'serchText':'Mandatory Hard Copy Invoice Number',
            'authorization': 'ADMIN_SUPPLIER'
          }
        ]
      },{
        'label' : 'global.userAccess.types.others',
        'value': [
          {
            'ariaLabel': 'access for buy now compact layout',
            'accessControl': 'accessForBuyNowCompactLayout',
            'translation' : 'global.userAccessTypes.buyNowCompactLayout',
            'title': 'Buy now compact layout',
            'serchText':'Buy now compact layout',
            'authorization': 'SUPER_ADMIN'
          },
          {
            'ariaLabel': 'accessForSms',
            'accessControl': 'accessForSms',
            'translation' : 'global.userAccessTypes.sms',
            'title': '',
            'serchText':'SMS',
            'authorization': 'BOTH'

          },
          {
            'ariaLabel': 'accessForInvoice',
            'accessControl': 'accessForInvoice',
            'translation' : 'global.menu.entities.invoice',
            'title': 'global.userAccess.title.invoice',
            'serchText':'Invoice',
            'authorization': 'ADMIN_SUPPLIER'
          },
          {
            'ariaLabel': 'accessForAdhocInvoice',
            'accessControl': 'accessForAdhocInvoice',
            'translation' : 'global.userAccessTypes.adhocInvoice',
            'title': 'global.userAccess.title.adhocInvoice',
            'serchText':'Adhoc Invoice',
            'authorization': 'ADMIN_SUPPLIER'
          },
          {
            'ariaLabel': 'accessForManageBuyer',
            'accessControl': 'accessForManageBuyer',
            'translation' : 'global.userAccessTypes.manageBuyer',
            'title': '',
            'serchText':'Manage Buyer',
            'authorization': 'ADMIN_SUPPLIER'
          },
          {
            'ariaLabel': 'accessForPriceUpdateApproval',
            'accessControl': 'accessForPriceUpdateApproval',
            'translation' : 'global.userAccessTypes.priceUpdateApproval',
            'title': 'global.userAccess.title.priceUpdateApproval',
            'serchText':'Price Update Approval',
            'authorization': 'ADMIN_SUPPLIER'
          },
          {
            'ariaLabel': 'accessForEditingPendingInvoice',
            'accessControl': 'accessForEditingPendingInvoice',
            'translation' : 'global.userAccessTypes.editPendingInvoice',
            'title': 'global.userAccess.title.editPendingInvoice',
            'serchText':'Edit Pending Invoice',
            'authorization': 'ADMIN_SUPPLIER'
          },
          {
            'ariaLabel': 'accessForAGHItemChange',
            'accessControl': 'accessForAGHItemChange',
            'translation' : 'global.userAccessTypes.itemDeviation',
            'title': 'global.userAccess.title.itemDeviation',
            'serchText':'Item Deviation',
            'authorization': 'ADMIN_SUPPLIER'
          },
          {
            'ariaLabel': 'accessForPickingList',
            'accessControl': 'accessForPickingList',
            'translation' : 'global.userAccessTypes.pickingListManagement',
            'title': 'global.userAccess.title.pickingListManagement',
            'serchText':'Picking List Management',
            'authorization': 'ADMIN_SUPPLIER'
          },
          {
            'ariaLabel': 'accessForMassInvoiceGeneration',
            'accessControl': 'accessForMassInvoiceGeneration',
            'translation' : 'global.userAccessTypes.massInvoiceGeneration',
            'title': 'global.userAccess.title.massInvoiceGeneration',
            'serchText':'Mass Invoice Generation',
            'authorization': 'ADMIN_SUPPLIER'
          },
          {
            'ariaLabel': 'accessForMassProcessOrder',
            'accessControl': 'accessForMassProcessOrder',
            'translation' : 'global.buttons.massProcessOrder',
            'title': 'global.userAccess.title.massProcessOrder',
            'serchText':'Mass Process Order',
            'authorization': 'ADMIN_SUPPLIER'
          },{
            'ariaLabel': 'accessForEditPrItemPrice',
            'accessControl': 'accessForEditPrItemPrice',
            'translation' : 'global.userAccessTypes.editPrItemPrice',
            'title': 'global.userAccess.title.editPreditPrItemPrice',
            'serchText':'Edit PR Item Price',
            'authorization': 'ADMIN_SUPPLIER'
          },
          {
            'ariaLabel': 'accessForWhatsappPo',
            'accessControl': 'accessForWhatsappPo',
            'translation' : 'global.userAccessTypes.whatsappPO',
            'title': 'global.userAccess.title.whatsappPO',
            'serchText':'Whatsapp PO',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForStockTransfer',
            'accessControl': 'accessForStockTransfer',
            'translation' : 'global.stockTransfer',
            'title': 'global.userAccess.title.stockTransfer',
            'serchText':'Stock Transfer',
            'authorization': 'ADMIN_BUYER'
          },{
            'ariaLabel': 'accessForStockTransferCostingView',
            'accessControl': 'accessForStockTransferCostingView',
            'translation' : 'global.userAccessTypes.StockTransferCosting',
            'title': 'global.userAccess.title.StockTransferCosting',
            'serchText':'Stock Transfer Costing',
            'authorization': 'ADMIN_BUYER'

        },
          {
            'ariaLabel': 'accessForDisableAuditLogNonAdminUsers',
            'accessControl': 'accessForDisableAuditLogNonAdminUsers',
            'translation' : 'global.userAccessTypes.accessForDisableAuditLogNonAdminUsers',
            'title': 'global.userAccess.title.auditlogAdminOnly',
            'serchText':'AuditLog - Admin Only',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForTemplateBuyingOnly',
            'accessControl': 'accessForTemplateBuyingOnly',
            'translation' : 'global.userAccessTypes.accessForTemplateBuyingOnly',
            'title': 'global.userAccess.title.accessForTemplateBuyingOnly',
            'serchText':'Template Buying Only',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForManageAdhoc',
            'accessControl': 'accessForManageAdhoc',
            'translation' : 'global.userAccessTypes.accessForManageAdhoc',
            'title': 'global.userAccess.title.manageAdhoc',
            'serchText':'Manage Adhoc',
            'authorization': 'ADMIN_BUYER'
          },
          {
            'ariaLabel': 'accessForApproverAlternateSupplierView',
            'accessControl': 'accessForApproverAlternateSupplierView',
            'translation' : 'global.userAccessTypes.accessForApproverAlternateSupplierView',
            'title': 'global.userAccess.title.accessForApproverAlternateSupplierView',
            'serchText':'Approver alternate supplier view',
            'authorization': 'ADMIN_BUYER'
          },
        ]
      }
    ],
  "OTP_EXPIRY_MINUTES" : 3,
  "OTP_EXCEPTIONS" : ['OTP_VERIFICATION_REQUIRED', 'OTP_VERIFICATION_FAILED', 'OTP_VERIFICATION_INTERNAL_ERROR'],
  "inclusiveOfTax": " (Inc.Tax)",
  "MAXIMUM_ALLOWED_ITEMS_PER_ORDER":200,
  "BUYER_ACCOUNT_TYPE":["ALL", "FULL_ACCOUNT", "LITE_ACCOUNT", "LICENSE_PACK"],
  "BUYER_VISIBILITY_TYPES": ["FULL_ACCOUNT", "LITE_ACCOUNT", "LICENSE_PACK"],  
  "emailOrigin": {
   "LOCATION_EZYAP":'EZYAP',
   "LOCATION_EZYPROCURE":'EZYPROCURE',
  },
  "REPORTS_DATE_VALIDATION" : 365,
  "MM_PROD_BUYERID":1967,
  "MM_TEST_BUYERID":581,
  "BILLING_STATUSES": ["ALL","BILLABLE", "NON_BILLABLE"],
  "BILLING_LIST": ["Lite_accounts","Standard", "Bulk","Discounted"],
  "OUTLET_TYPES": ["All","HQ","Sub-Outlet"]
});
